const contactFormUrl = import.meta.env.VITE_OLYMPE_CONTACT_URL

interface Props {
  onClick: () => void
}

export function PaymentModalContactFooter({ onClick }: Readonly<Props>) {
  return (
    <footer>
      <p className={'text-base'}>
        Pour toute autre demande, écrivez-nous à{' '}
        <a href={contactFormUrl} className={'font-semibold'} target={'_blank'} rel={'noreferrer'}>
          support@olympe.legal
        </a>
      </p>
      <br />
      <p>
        Pour vous déconnecter,{' '}
        <span onClick={onClick} className={'hover:cursor-pointer'}>
          cliquez ici
        </span>
        .
      </p>
    </footer>
  )
}
